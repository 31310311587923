var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Register V2"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('span',{staticClass:"d-flex align-items-center justify-content-center"},[_c('b-img',{staticClass:"w-50",attrs:{"src":_vm.imgLogo,"alt":"logo"}})],1),_c('b-card-title',{staticClass:"my-1"},[_vm._v(" "+_vm._s(_vm.$t('Register'))+" ")]),_c('validation-observer',{ref:"registerForm",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{staticClass:"auth-register-form mt-2",on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.register)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Full Name'),"label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Full Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-input',{class:classes,attrs:{"state":errors.length > 0 ? false:null,"placeholder":_vm.$t('Full Name')},model:{value:(_vm.user.name),callback:function ($$v) {_vm.$set(_vm.user, "name", $$v)},expression:"user.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Registration Number'),"label-for":"r_code"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Registration Number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-input',{class:classes,attrs:{"id":"r_code","state":errors.length > 0 ? false:null,"placeholder":"1234"},model:{value:(_vm.user.r_code),callback:function ($$v) {_vm.$set(_vm.user, "r_code", $$v)},expression:"user.r_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('validation-provider',{attrs:{"name":"Email","vid":"email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-group',{class:classes,attrs:{"label":"Email","label-for":"register-email"}},[_c('b-form-input',{class:classes,attrs:{"id":"register-email","name":"register-email","placeholder":"john@example.com"},model:{value:(_vm.user.email),callback:function ($$v) {_vm.$set(_vm.user, "email", $$v)},expression:"user.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$t('Sector'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-group',{attrs:{"label":_vm.$t('Sector'),"label-for":"sector"}},[_c('v-select',{class:classes,attrs:{"options":_vm.sector,"reduce":function (val) { return val.value; },"clearable":true,"input-id":"sector"},model:{value:(_vm.user.sector_id),callback:function ($$v) {_vm.$set(_vm.user, "sector_id", $$v)},expression:"user.sector_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,true)}),_c('b-form-group',{attrs:{"label":_vm.$t('Office'),"label-for":"register-username"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Office'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-form-input',{class:classes,attrs:{"placeholder":_vm.$t('Office')},model:{value:(_vm.user.office),callback:function ($$v) {_vm.$set(_vm.user, "office", $$v)},expression:"user.office"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"register-password","label":_vm.$t('Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Password'),"rules":"required|min:6","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-input-group',{staticClass:"input-group-merge",class:classes},[_c('b-form-input',{staticClass:"form-control-merge",class:classes,attrs:{"id":"register-password","type":_vm.passwordFieldType,"placeholder":"············"},model:{value:(_vm.user.password),callback:function ($$v) {_vm.$set(_vm.user, "password", $$v)},expression:"user.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-form-group',{attrs:{"label-for":"c_password","label":_vm.$t('Confirm Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Confirm Password'),"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('b-input-group',{staticClass:"input-group-merge",class:classes},[_c('b-form-input',{staticClass:"form-control-merge",class:classes,attrs:{"id":"c_password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"register-password","placeholder":"············"},model:{value:(_vm.user.c_password),callback:function ($$v) {_vm.$set(_vm.user, "c_password", $$v)},expression:"user.c_password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('b-button',{attrs:{"variant":"primary","block":"","type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('Sign up'))+" ")])],1)]}}])}),_c('p',{staticClass:"text-center mt-2"},[_c('span',[_vm._v(_vm._s(_vm.$t('Already have an account?')))]),_c('b-link',{attrs:{"to":{name:'auth-login'}}},[_c('span',[_vm._v(" "+_vm._s(_vm.$t('Sign in instead')))])])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }