const sector = [
  { label: 'Comercial', value: 1 },
  { label: 'Industrial', value: 2 },
  { label: 'Financeiro', value: 3 },
  { label: 'Expedição & Recebimento', value: 4 },
  { label: 'Importação & Exportação', value: 5 },
  { label: 'Administração', value: 6 },
  { label: 'Recursos humanos', value: 7 },
  { label: 'Compras', value: 8 },
  { label: 'TI', value: 9 },
  { label: 'Manutenção', value: 10 },
  { label: 'Geral', value: 11 },
  { label: 'Marketing Interno', value: 12 },
  { label: 'Recepção', value: 13 },
  { label: 'Comercial (CAC)', value: 14 },
  { label: 'Comercial Internacional', value: 15 },
  { label: 'Produção', value: 16 },
  { label: 'Engenharia', value: 17 },
  { label: 'Pós venda', value: 18 },
  { label: 'Assistência técnica', value: 19 },
  { label: 'SAC', value: 20 },
  { label: 'P&D', value: 21 },
  { label: 'Certificação', value: 22 },
  { label: 'Treinamento', value: 23 },
  { label: 'Jurídico', value: 24 },
  { label: 'Qualidade', value: 25 },
  { label: 'Logistica', value: 26 },
  { label: 'Trade', value: 27 },
]

const head_office = [
  { label: 'COSAMA SEDE', value: 1 },
  { label: 'TABATINGA', value: 2  },
  { label: 'CODAJAS', value: 3 },
  { label: 'AUTAZES', value: 4 },
  { label: 'CARAUARI', value: 5 },
  { label: 'JURUA', value: 6 },
  { label: 'EIRUNEPE', value: 7 },
  { label: 'SÃO PAULO DE OLIVENÇA', value: 8 },
  { label: 'BENJAMIN CONSTANT', value: 9 },
  { label: 'ALVARÃES', value: 10 },
  { label: 'CAREIRO DA VARZEA', value: 11 },
  { label: 'ITAMARATI', value: 12 },
  { label: 'MANAQUIRI', value: 13 },
  { label: 'ATALAIA DO NORTE', value: 14 },
  { label: 'NOVA OLINDA DO NORTE', value: 15 },
  { label: 'NHAMUNDA', value: 16 },
  { label: 'BOCA DO ACRE', value: 17 },
]

const states = [
  { value: 'AC', label: 'Acre' },
  { value: 'AL', label: 'Alagoas' },
  { value: 'AP', label: 'Amapá' },
  { value: 'AM', label: 'Amazonas' },
  { value: 'BA', label: 'Bahia' },
  { value: 'CE', label: 'Ceará' },
  { value: 'DF', label: 'Distrito Federal' },
  { value: 'ES', label: 'Espírito Santo' },
  { value: 'GO', label: 'Goiás' },
  { value: 'MA', label: 'Maranhão' },
  { value: 'MT', label: 'Mato Grosso' },
  { value: 'MS', label: 'Mato Grosso do Sul' },
  { value: 'MG', label: 'Minas Gerais' },
  { value: 'PA', label: 'Pará' },
  { value: 'PB', label: 'Paraíba' },
  { value: 'PR', label: 'Paraná' },
  { value: 'PE', label: 'Pernambuco' },
  { value: 'PI', label: 'Piauí' },
  { value: 'RJ', label: 'Rio de Janeiro' },
  { value: 'RN', label: 'Rio Grande do Norte' },
  { value: 'RS', label: 'Rio Grande do Sul' },
  { value: 'RO', label: 'Rondônia' },
  { value: 'RR', label: 'Roraima' },
  { value: 'SC', label: 'Santa Catarina' },
  { value: 'SP', label: 'São Paulo' },
  { value: 'SE', label: 'Sergipe' },
  { value: 'TO', label: 'Tocantins' },
]

const status = [
  { label: 'Ativo', value: 1 },
  { label: 'Desativado', value: 0 },
]

export default {
  sector,
  head_office,
  status,
  states,
}
