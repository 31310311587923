<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Register V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Register-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
      
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <span class="d-flex align-items-center justify-content-center">
            <b-img class="w-50"
              :src="imgLogo"
              alt="logo"
            />
          </span>
          <b-card-title class="my-1">
           {{$t('Register')}}
          </b-card-title>
          <!-- form -->
          <validation-observer
            ref="registerForm"
            v-slot="{ handleSubmit }"
          >
            <b-form
              class="auth-register-form mt-2"
              @submit.prevent="handleSubmit(register)"
            >
              <!-- username -->
              <b-form-group
                :label="$t('Full Name')"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors, classes }"
                  :name="$t('Full Name')"
                  rules="required"
                >
                  <b-form-input
                    :class="classes"
                    v-model="user.name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Full Name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- r_code -->
              <b-form-group
                :label="$t('Registration Number')"
                label-for="r_code"
              >
                <validation-provider
                  #default="{ errors, classes }"
                  :name="$t('Registration Number')"
                  rules="required"
                >
                  <b-form-input
                    :class="classes"
                    id="r_code"
                    v-model="user.r_code"
                    :state="errors.length > 0 ? false:null"
                    placeholder="1234"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- email -->
              <validation-provider
                #default="{ errors, classes }"
                name="Email"
                vid="email"
                rules="required|email"
              >
                <b-form-group
                  label="Email"
                  label-for="register-email"
                  :class="classes"
                >
                  <b-form-input
                    id="register-email"
                    v-model="user.email"
                    name="register-email"
                    :class="classes"
                    placeholder="john@example.com"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <!-- sector -->
              <validation-provider
                #default="{ errors, classes }"
                :name="$t('Sector')"
                rules="required"
              >              
                <b-form-group
                :label="$t('Sector')"
                label-for="sector"
                >
                  <v-select
                    :class="classes"
                    v-model="user.sector_id"
                    :options="sector"
                    :reduce="(val) => val.value"
                    :clearable="true"
                    input-id="sector"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </validation-provider>
              <!-- office -->
              <b-form-group
                :label="$t('Office')"
                label-for="register-username"
              >
                <validation-provider
                  #default="{ errors, classes }"
                  :name="$t('Office')"
                  rules="required"
                >
                  <b-form-input
                    :class="classes"
                    v-model="user.office"
                    :placeholder="$t('Office')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- password -->
              <b-form-group
                label-for="register-password"
                :label="$t('Password')"
              >
                <validation-provider
                  #default="{ errors, classes }"
                  :name="$t('Password')"
                  rules="required|min:6"
                  vid="password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="classes"
                  >
                    <b-form-input
                      id="register-password"
                      v-model="user.password"
                      :class="classes"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label-for="c_password"
                :label="$t('Confirm Password')"
              >
                <validation-provider
                  #default="{ errors, classes }"
                  :name="$t('Confirm Password')"
                  rules="required|confirmed:password"
                >
                  <b-input-group
                    class="input-group-merge"
                    :class="classes"
                  >
                    <b-form-input
                      :class="classes"
                      id="c_password"
                      v-model="user.c_password"
                      class="form-control-merge"
                      :type="passwordFieldType"
                      :state="errors.length > 0 ? false:null"
                      name="register-password"
                      placeholder="············"
                    />
                    <b-input-group-append is-text>
                      <feather-icon
                        :icon="passwordToggleIcon"
                        class="cursor-pointer"
                        @click="togglePasswordVisibility"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <b-button
                variant="primary"
                block
                type="submit"
              >
                {{$t('Sign up')}}
              </b-button>
            </b-form>
          </validation-observer>

          <p class="text-center mt-2">
            <span>{{$t('Already have an account?')}}</span>
            <b-link :to="{name:'auth-login'}">
              <span>&nbsp;{{$t('Sign in instead')}}</span>
            </b-link>
          </p>
        </b-col>
      </b-col>
    <!-- /Register-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow, BCol, BLink, BButton, BForm, BFormCheckbox, BFormGroup, BFormInput, BInputGroup, BInputGroupAppend, BImg, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { required, email, confirmed, min } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import useJwt from '@/auth/jwt/useJwt'
import constants from "@/common/constants"
import vSelect from "vue-select";
import axios from '@axios'
export default {
  components: {
    BRow,
    BImg,
    BCol,
    BLink,
    BButton,
    BForm,
    BCardText,
    BCardTitle,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      user:{
        r_code: '',
        name: '',
        office:'',
        sector_id: '',
        password: '',
        c_password: '',
        email:'',
      },
      sector: constants.sector,
      sideImg: require('@/assets/images/pages/register-v2.svg'),
      imgLogo: require('@/assets/images/logo/logo_cosama.png'),
      // validation
      required,
      email,
      confirmed,
      min,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/register-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    register() {
      this.$refs.registerForm.validate().then(success => {
        if (success) {
          axios.post('auth/register',{...this.user}, {showToast:true})
            .then(response => {
              this.$router.push('/')
            })
            .catch(error => {
              this.$refs.registerForm.setErrors(error.response.data.error)
            })
        }
      })
    },
  },
}
/* eslint-disable global-require */
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
@import "@core/scss/vue/libs/vue-select.scss";
</style>
